import 'jquery';
require('bootstrap-sass/assets/javascripts/bootstrap/collapse');
require('bootstrap-sass/assets/javascripts/bootstrap/modal');
require('bootstrap-sass/assets/javascripts/bootstrap/dropdown');
require('bootstrap-sass/assets/javascripts/bootstrap/tab');
require('bootstrap-sass/assets/javascripts/bootstrap/transition');
require('bootstrap-sass/assets/javascripts/bootstrap/alert');
require('bootstrap-sass/assets/javascripts/bootstrap/button');
require('bootstrap-sass/assets/javascripts/bootstrap/carousel');
require('bootstrap-sass/assets/javascripts/bootstrap/tooltip');
require('bootstrap-sass/assets/javascripts/bootstrap/popover');
require('bootstrap-sass/assets/javascripts/bootstrap/scrollspy');
require('bootstrap-sass/assets/javascripts/bootstrap/affix');

(function($) {

	// Use this variable to bind together theme specific functions and events.
	window.Sage = $.extend({}, Backbone.Events);

	window.Sage.Util = {
		wp_ajax: function(action, data) {
			return $.post(SageStrings.ajaxurl, $.extend({}, data, {action: action}));
		},
		parseHTML: function(html) {
			var elements = $.parseHTML($.trim(html));
			if(!elements.length) { return null; }
			return $(elements[0]);
		},
		get_preload: function(name) {
			try {
				return JSON.parse(jQuery('#preload_'+name).html());
			} catch(e) {
				return null;
			}
		},
		prefix_obj_keys: function(obj, prefix) {
			var result = {};
			for(var key in obj) {
				result[prefix+'_'+key] = obj[key];
			}
			return result;
		},
		get_query_params: function() {
			var query = window.location.search.substring(1);
			var result = {};
			var parts = query.split('&');
			for (var i = 0; i < parts.length; i++) {
				var keyvalue = parts[i].split('=');
				result[decodeURIComponent(keyvalue[0])] = decodeURIComponent(keyvalue[1] || '');
			}
			return result;
		},
		get_query_param: function(name) {
			return Sage.Util.get_query_params()[name];
		},
		make_query_string: function(query) {
			var parts = [];
			for(var key in query) {
				parts.push(key+'='+query[key]);
			}
			var result = parts.join('&');
			if(result) { result = '?'+result; }
			return result;
		},
		format: function() {
			var args = Array.prototype.slice.call(arguments);
			var content = args.shift();
			for (var i=0; i < args.length; i++) {
				var replacement = '{' + i + '}';
				content = content.replace(replacement, args[i]);
			}
			return content;
		},
		decode_html(value) {
			return $("<textarea/>").html(value).text();
		},
		encode_html(value) {
			return $('<textarea/>').text(value).html();
		},
	};

})(jQuery);
