(function($) {

	var extend_edges = Sage.Util.extend_edges = function(element) {

		var windowWidth = $(window).width();
		var containerWidth = $('.container').width();
		var compensation = (windowWidth - containerWidth) / 2;

		element.css('margin-left', -compensation);
		element.css('margin-right', -compensation);

	};

	$.fn.extend_edges = function() {
		return this.each(function(index, element) {
			var el = $(element);
			var extend_el = function() { extend_edges(el); };
			$(window).resize(extend_el);
			$(window).load(extend_el);
		});
	};

})(jQuery);
