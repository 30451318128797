(function($) {

	Sage.Modal = Backbone.View.extend({
		events: {
			'show.bs.modal': 'onshow',
			'shown.bs.modal': 'onshown',
			'hide.bs.modal': 'onhide',
			'hidden.bs.modal': 'onhidden',
		},
		show: function() {
			this.$el.modal('show');
		},
		hide: function() {
			this.$el.modal('hide');
		},
	});

	Sage.AjaxModal = Sage.Modal.extend({
		modal_status: 'unloaded',
		modal_shown: false,
		load: function(action, action_data) {
			var self = this;
			if(self.modal_status !== 'unloaded' && self.modal_status !== 'loaded') { return false; }

			action = action || self.action;
			action_data = action_data || self.action_data;
			if(!action) { throw 'AjaxModal must be given an action'; }
			self.modal_status = self.modal_status === 'unloaded' ? 'loading' : 'reloading';

			Sage.Util.wp_ajax(action, action_data).then(
				function(response) {
					if(response === '' || response === '0') { return console.error('Unable to load modal'); }

					var $newelement = Sage.Util.parseHTML(response);
					if(!$newelement) { return console.error('Unable to load modal: Cannot parse HTML'); }

					if(self.modal_status === 'loading') {
						self.$el.replaceWith($newelement);
						self.setElement($newelement);
						if(self.modal_shown) { self.$el.modal('show'); }
					} else if(self.modal_status === 'reloading') {
						self.$el.find('.modal-content').replaceWith($newelement.find('.modal-content'));
					}

					self.modal_status = 'loaded';
				}, function(e) {
					console.error('Unable to load modal: '+e);
				}
			);
		},
		show: function() {
			this.modal_shown = true;
			if(this.modal_status === 'loaded' || this.modal_status === 'reloading') { this.$el.modal('show'); }
		},
		hide: function() {
			this.modal_shown = false;
			if(this.modal_status === 'loaded' || this.modal_status === 'reloading') { this.$el.modal('hide'); }
		},
	});

	Sage.TempModal = Sage.AjaxModal.extend({
		initialize: function(options) {
			options = options || {};
			this.load(options.action, options.action_data);
		},
		onhidden: function() {
			this.remove();
		}
	});

})(jQuery);