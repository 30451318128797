(function($) {

	// All pages
	Sage.on('page_init', function() {
		window.opener = window;

		$(".full-height").css('height', function() {
			return $(this).parent().height() - parseInt($(this).css('margin-bottom')) - parseInt($(this).css('margin-top'));
		});

		//My account
		$('li.launch-modal a, .link-launch-modal').click(function(e){
			e.preventDefault();
			$($(this).attr('href')).modal();
		});

		$('.launch-modal.course-modal').click(function(e){
			var entry = $(this).closest('.enrollment-data');

			$('.modal-entry .course-objectives').html('');
			$('.modal-entry .course-title').text(entry.data('course-title'));
			$('.modal-entry .course-summary').html(entry.data('course-summary'));

		});

		$('.banner-play-image img').on('click', function() {
			$('#videoModal').modal('show');
		});

		$('.launch-modal.articulate-modal').click(function(e){
			e.preventDefault();

			var data = {
				'action': 'eliteceu_set_first_view',
				'eid': $(this)[0].getAttribute('data-eid')
			};
			jQuery.post('/wp/wp-admin/admin-ajax.php', data, function(response) { });

			var verification = {
				'action': 'eliteceu_course_verification_action',
				'eid'   : $(this).closest('.enrollment-data').data('enrollment-id')
			};

			var that = this;

      var viewer = window.open("", "_blank");

			jQuery.post('/wp/wp-admin/admin-ajax.php', verification, function(response) {
				if (!response) {
					alert("You have already reached the maximum amount of hours for today. You may run this course tomorrow.");
          viewer.close();
				} else {
					var entry = $(that).closest('.enrollment-data');

          viewer.location.href = $(that).attr('href');

					var data = {
						'action': 'eliteceu_course_start_action',
						'eid': entry.data('enrollment-id'),
					};

					jQuery.post('/wp/wp-admin/admin-ajax.php', data, function(response) {
						if (!response) {
							console.log('Attempt to add Enrollment ID: ' + data.eid + ' to Session Variable failed.');
						}
					});
				}
			});
		});

		$('.blocked-link').click(function(e){
			e.preventDefault();

			var course = $(this).closest('.enrollment-data').find('.course-title');
			alert('Course: ' + course.text() + ' has been completed and the review is also expired.....');
		});

		$('#toggle-scrolling').click(function(e){
			$('.scroll-wrapper').toggleClass('scrolling');
		});

		$('.button-toggle-grid').click(function(e){
			$('.enrolled-students, .assigned-courses').toggleClass('grid table');
		});

		$('.button-reverse').click(function(e){
			$('.scroll-wrapper').find('li').each(function(item){
				$(this).parent().prepend(this);
			});
			$(this).toggleClass('reversed');
		});

		$('#enrolled-students-sorting').change(function(e){
			$('tr.student').sort(function(a,b){
				var an = $(a).data('name').toLowerCase();
				var bn = $(b).data('name').toLowerCase();
				var ac = parseInt($(a).data('courses'), 10);
				var bc = parseInt($(b).data('courses'), 10);
				switch (e.target.value) {
					case 'name-asc':
						return an !== bn ? an > bn ? 1 : -1 : ac !== bc ? ac > bc ? 1 : -1 : 0;
					case 'name-desc':
						return an !== bn ? an < bn ? 1 : -1 : ac !== bc ? ac > bc ? 1 : -1 : 0;
					case 'courses-asc':
						return ac !== bc ? ac > bc ? 1 : -1 : an !== bn ? an > bn ? 1 : -1 : 0;
					case 'courses-desc':
						return ac !== bc ? ac < bc ? 1 : -1 : an !== bn ? an > bn ? 1 : -1 : 0;
				}
				return 0;
			}).appendTo('.enrolled-students tbody');
		});

		$('#enrolled-students-search').on('keyup change', function(e){
			$('tr.student').each(function(index, element){
				var value = e.target.value.toLowerCase();
				if (!value){
					$(element).removeClass('filtered');
				} else {
					if ($(element).html().toLowerCase().match(value)){
						$(element).removeClass('filtered');
					} else {
						$(element).addClass('filtered');
					}
				}
			});
		});

		$('#assigned-courses-sorting').change(function(e){
			$('.assigned-courses tbody').each(function(index, list){
				$(list).find('.course').sort(function(a,b){
					var as = parseInt($(a).data('start'), 10);
					var bs = parseInt($(b).data('start'), 10);
					var ae = parseInt($(a).data('end'), 10);
					var be = parseInt($(b).data('end'), 10);
					switch (e.target.value) {
						case 'start-date-asc':
							return as !== bs ? as > bs ? 1 : -1 : 0;
						case 'start-date-desc':
							return as !== bs ? as < bs ? 1 : -1 : 0;
						case 'end-date-asc':
							return ae !== be ? ae > be ? 1 : -1 : 0;
						case 'end-date-desc':
							return ae !== be ? ae < be ? 1 : -1 : 0;
					}
					return 0;
				}).appendTo(list);
			});
		});

		$('#assigned-courses-search').on('keyup change', function(e){
			$('tr.course').each(function(index, element){
				var value = e.target.value.toLowerCase();
				if (!value){
					$(element).removeClass('filtered');
				} else {
					if ($(element).html().toLowerCase().match(value)){
						$(element).removeClass('filtered');
					} else {
						$(element).addClass('filtered');
					}
				}
			});
		});

		$('.left-scroll-button').click(function(e){
			$(this).closest('.scroll-wrapper').find('.scroller').stop(true).animate({
				scrollLeft: "-=400px"
			}, "fast");
		});

		$('.right-scroll-button').click(function(e){
			$(this).closest('.scroll-wrapper').find('.scroller').stop(true).animate({
				scrollLeft: "+=400px"
			}, "fast");
		});

		//sub-menus
		$('.menu-item-has-children').click(function(){
			if ($(this).hasClass('smo')) {
				$('nav.nav-primary').removeClass('submenu-open');
				$(this).removeClass('smo');
			} else {
				$('li').removeClass('smo');
				$('nav.nav-primary').addClass('submenu-open');
				$(this).addClass('smo');
			}
		});

		//file upload
		var shorten = function(s, l) {
			if (s.length > l) {
				s = s.substring(0, l) + '...';
			}
			return s;
		};
		$('.ginput_container_fileupload').attr('data-filename', function(){
			return $('.ginput_container_fileupload').prev().text();
		});
		$(document.body).on('change', 'input[type=file]', function(e) {
			var input = e.currentTarget;
			if (input.files.length > 0) {
				$(input).parent().attr('data-filename', shorten(input.files[0].name, 30));
				input.css('color', '#000');
			}
		});

		//makes about us boxes flex items
		$(".item-flex").each(function(){
			$(this).parent().addClass("row-flex");
			//$(this).find(".so-panel").css("height", "100%");
		});


		//testimonial animations
		$(".testimonial").click(function(){
			$(".testimonial").removeClass("active").addClass("non-active");
			$(this).removeClass("non-active").addClass("active");
		});

		Backbone.history.start({pushState: true});

		// Site header
		$('.site-header .mobile-menu .navbar-toggle').click(function() {
			$(this).toggleClass('open');
		});
		$('.site-header .user-area .logout-item a').click(function() {
			window.location = SageStrings.logouturl;
			return false;
		});

		$('.cell-slides').slick({
			centerMode: true,
			centerPadding: '30px',
			speed:1200,
			infinite: true,
			slidesToShow: 2,
			variableWidth: true,
			prevArrow:"<img class='a-left control-c prev slick-prev' src='" + window.location.origin + "/app/uploads/2021/06/Group-7.svg'>",
			nextArrow:"<img class='a-right control-c next slick-next' src='" + window.location.origin + "/app/uploads/2021/06/Group-7-Copy-3.svg'>",
			responsive: [
			  {
				breakpoint: 768,
				settings: {
				  arrows: true,
				  centerMode: true,
				  centerPadding: '20px',
				  slidesToShow: 1
				}
			  },
			  {
				breakpoint: 480,
				settings: {
				  arrows: true,
				  centerMode: true,
				  centerPadding: '20px',
				  slidesToShow: 1
				}
			  }
			]
		});

	});

	Sage.on('bulk_user_registration_page_init', function() {
		//check for non duplicate emails and usernames
		function dupCheck(cell) {
			var itemArray = [];
			$('td.' + cell).each(function(){
				// put all emails on array
				itemArray.push($(this).text());
			});
			//sort because comparison looks at following entry
			itemArray.sort();
			//compare
			for (var i = 0; i < itemArray.length - 1; i++) {
				if (itemArray[i + 1] === itemArray[i]) {
					// if match
					$('td.' + cell + ':contains("' + itemArray[i] + '")').each(function(){
						if (!$(this).hasClass('btn-danger')) {
							// set cell status
							$(this).addClass('btn-danger');
							$(this).attr('title', "Duplicate " + cell.substr(0,1).toUpperCase()+cell.substr(1));
							//set row status
							var rowStatus = $(this).siblings('.status');
							$(rowStatus).addClass('btn-danger');
							rowStatus[0].lastElementChild.className = "fa fa-exclamation-circle";
							rowStatus.attr('title', "Invalid Information");
						}
					});
				}
			}
		}

		dupCheck('username');

		//check row validation
		var isValid = true;
		$('td.status').each(function(){
			if ($(this).hasClass('btn-danger')) {
				isValid = false;
			}
		});
		if (!isValid) {
			$('input.gform_next_button').hide();
			$('input, select').click(function(e){
				if (e.currentTarget.type !== "button") {
					e.preventDefault();
					alert("Make sure all your user information is valid before continuing.");
				}
			});
		}

		//add prices
		var totalPrice = 0;
		$('td.price-cell').each(function(){
			totalPrice += parseInt($(this).text());
			$(this).text('$' + $(this).text());
		});
		var userCount = $('.total-price').attr('data-user-count');
		$('.total-price').text("Total Amount: $" + (totalPrice * userCount));
	});

	// Home page.
	Sage.on('home_page_init', function() {
		// JavaScript to be fired on the home page
		var data = {
			'action': 'eliteceu_update_user_last_login_action'
		};

		jQuery.post('/wp/wp-admin/admin-ajax.php', data, function(response) {
			if (response) {
				alert("Your Licenses might be out of date. Please update your profile information .");
				window.location.replace("/update-user-account/");
			}
		});
	});

	Sage.on('single_state_page_init', function() {
		$('#interactive-map').on('hidden.bs.collapse', function() {
			$('[href="#interactive-map"], [data-toggle="#interactive-map"]').button("reset");
		}).on('shown.bs.collapse', function() {
			$('[href="#interactive-map"], [data-toggle="#interactive-map"]').button("expanded");
		});
	});

	Sage.on('single_ppc_page_page_init', function() {
		Sage.WooCommerce.init_add_to_cart_buttons();

		//Internal Navigation
		if( $(".nav-box").length ) {

		  function scrollTo(target) {
		    $('html,body').stop().animate({scrollTop: $(target).offset().top-150}, 600);
		  }

		  function throttle(fn, threshhold, scope) {
		    threshhold = threshhold || 250;
		    var last, deferTimer;
		    return function () {
		      var context = scope || this;

		      var now = +new Date();
		      var args = arguments;
		      if (last && now < last + threshhold) {
		        clearTimeout(deferTimer);
		        deferTimer = setTimeout(function () {
		          last = now;
		          fn.apply(context, args);
		        }, threshhold);
		      } else {
		        last = now;
		        fn.apply(context, args);
		      }
		    };
		  }

		  //make textwidget 100% height to allow position: sticky to work
		  $(".ethics-nav .textwidget").css('height', '100%');

		  //click navigation
		  $(".nav-box span").click(function() {
		    scrollTo(".sow-headline#" + $(this).attr("id"));
		  });

		  function updateActiveBar(navitem) {
		    $(".nav-box span").removeClass("active");
		    var findWidth = $(navitem).outerWidth()-5;
		    navitem.addClass("active");
		  }

		  // Have the active bar follow the user's progress down the page
		  $(window).scroll(throttle(function() {
		    var scroll = $(window).scrollTop();

		    // Find the section that the user is looking at
		    var section = $(".sow-headline").first();
		    $(".sow-headline").each(function() {
		      if(scroll > $(this).offset().top-300) {
		        section = $(this);
		      }
		    });

		    updateActiveBar($(".nav-box span[id='"+section.attr("id")+"']"));

		  }, 100));

		}

		$(".ethics-cta .show_add_to_cart").click(function() {
  			$( ".ethics-btn.show_add_to_cart" ).trigger( "click" );
  		});

	});

	Sage.on('single_product_page_init', function() {

		var wWidth = $(window).width();
		var rWidth = $('.related.products').outerWidth();

		$(".greybar-bg").css("width", wWidth);
		$(".greybar-bg").css("left", -1*(wWidth-rWidth)/2);

		$(window).resize(function() {

			$(".greybar-bg").css("width", wWidth);
			$(".greybar-bg").css("left", -1*(wWidth-rWidth)/2);

		});

	});

	Sage.on('contact_us_page_init', function() {

		if (location.hash === "#course-suggestion") {

			$(".ginput_container_select option:last-of-type").prop("selected", true);

		}

	});

	// DOM-based Routing - Based on http://goo.gl/EUTi53 by Paul Irish
	Sage.Util.triggerPageEvents = function() {
		// Fire common init JS
		Sage.trigger('page_init');

		// Fire page-specific init JS, and then finalize JS
		$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
			Sage.trigger(classnm+'_page_init');
			Sage.trigger(classnm+'_page_finalize');
		});

		// Fire common finalize JS
		Sage.trigger('page_finalize');
	};

	// Load Events
	$(document).ready(Sage.Util.triggerPageEvents);

})(jQuery);
