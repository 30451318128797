(function($) {

	Sage.WooCommerce = {
		init_add_to_cart_buttons: function() {
			$('body').on('click', '.show_add_to_cart', function() {
				var add_to_cart_modal = new Sage.TempModal({
					el: $('<div/>').appendTo($(this).parent()),
					action: 'add_to_cart_modal',
					action_data: {product: $(this).attr('data-product-id')}
				});
				add_to_cart_modal.show();
				return false;
			});
		},
		init_archive: function() {
			var model = new Sage.WooCommerce.ArchiveModel();
			var filter = new Sage.WooCommerce.ArchiveFilterView({model: new Sage.Submodel(model, 'filter'), el: $('.archive-filter')});
			var breadcrumbs = new Sage.WooCommerce.ArchiveBreadcrumbsView({model: model, el: $('.archive-breadcrumbs')});
			var sorting = new Sage.WooCommerce.ArchiveSortingView({model: model, el: $('.archive-sorting')});
		},
	};

	Sage.on('single_product_page_init', function() {
		Sage.WooCommerce.init_add_to_cart_buttons();

		$('.product-description-wrapper, .related-wrapper').extend_edges();
	});

	Sage.on('archive_page_init', function() {
		// Bail early if this is not the store archive page.
		if ( ! $( 'body' ).hasClass( 'post-type-archive-product' ) ) {
			return;
		}

		Sage.WooCommerce.init_add_to_cart_buttons();
		Sage.WooCommerce.init_archive();
	});

})(jQuery);
