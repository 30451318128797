(function($) {

	var Submodel = Sage.Submodel = function(parent, attribute) {
		this.parent = parent;
		this.attribute = attribute;
		this.listenTo(this.parent, 'change:'+this.attribute, this.parent_changed);
	};

	_.extend(Submodel.prototype, Backbone.Events, {
		get: function() {
			if(arguments.length === 0) {
				return this.parent.get(this.attribute);
			} else if(arguments.length === 1) {
				var value = this.parent.get(this.attribute);
				if(!value) { value = {}; }
				return value[arguments[0]];
			}
		},
		previous: function() {
			if(arguments.length === 0) {
				return this.parent.previous(this.attribute);
			} else if(arguments.length === 1) {
				var value = this.parent.previous(this.attribute);
				if(!value) { value = {}; }
				return value[arguments[0]];
			}
		},
		set: function() {
			if(arguments.length === 1) {
				this.parent.set(this.attribute, arguments[0]);
			} else if(arguments.length === 2) {
				var value = this.parent.get(this.attribute);
				if(!value) { value = {}; } else { value = _.clone(value); }
				value[arguments[0]] = arguments[1];
				this.parent.set(this.attribute, value);
			}
		},
		parent_changed: function(model, value, options) {
			if(_.isObject(value)) {
				var prev = this.parent.previous(this.attribute);
				var attr;
				for(attr in value) {
					if(value[attr] !== prev[attr]) {
						this.trigger('change:' + attr, this, value[attr], options);
					}
				}
				for(attr in prev) {
					if(attr in value) { continue; }
					this.trigger('change:' + attr, this, void(0), options);
				}
			}
			this.trigger('change', this, options);
		},
	});

})(jQuery);