(function($) {

	var ArchiveModel = Sage.WooCommerce.ArchiveModel = Backbone.Model.extend({
		initialize: function(options) {
			this.do_reload = _.debounce(_.bind(this.reload, this), 100);
			this.setup_router();
			this.set_values();
			this.on('change', this.do_reload);
			this.on('change:filter', this.reset_page);
		},
		setup_router: function() {
			this.router = new Backbone.Router();
			this.router.route('*any', 'any', _.bind(this.set_values, this));
		},
		set_values: function() {
			var params = Sage.Util.get_query_params();
			var match = window.location.pathname.match('/\/'+SageStrings.shop_page_name+'\/?(?:page\/(\d+)\/?)?/');
			var page = (match && match[1]) ? parseInt(match[1]) : 1;

			var data = {
				filter: {
					states: params.states || '',
					product_cat: params.product_cat || '',
					search: params.s || params.search || '',
					course_duration: {},
				},
				sorting: params.sorting || 'popularity',
				page: page,
			};

			if(params.course_duration_min) { data.filter.course_duration.min = params.course_duration_min; }
			if(params.course_duration_max) { data.filter.course_duration.max = params.course_duration_max; }

			this.set(data);
		},
		reset_page: function() {
			this.set('page', 1);
		},
		fade_out: function() {
			if($('.loop').is('.fading')) { return; }
			$('.loop').addClass('fading').stop().animate(
			 	{opacity: 0},
				500*$('.loop').css('opacity'),
				_.bind(this.fade_done, this)
			);
		},
		fade_done: function() {
			this.request.done(_.bind(function(response) {
				if(response === '' || response === '0') { return console.error('Unable to reload archive'); }
				$('.loop').html(response);
			}, this));
			this.request.always(_.bind(function() {
				this.request = null;
				this.fade_in();
			}, this));
		},
		fade_in: function() {
			$('.loop').removeClass('fading').stop().animate({opacity: 1}, 500);
		},
		reload: function() {
			if(this.request) { this.request.abort(); }

			var filtervalues = {};
			var filters = this.get('filter');
			for(var filter in filters) {
				var value = filters[filter];
				if(_.isObject(value)) {
					_.extend(filtervalues, Sage.Util.prefix_obj_keys(value, filter));
				} else if(value) {
					filtervalues[filter] = value;
				}
			}

			var sortingvalues = {};
			if(this.get('sorting')) {
				sortingvalues.sorting = this.get('sorting');
			}

			var pages = '';
			if(this.get('page') > 1) {
				pages = 'page/'+this.get('page')+'/';
			}

			var query = Sage.Util.make_query_string(_.extend(filtervalues, sortingvalues));
			this.router.navigate(SageStrings.shop_page_name+'/'+pages+query);

			this.request = Sage.Util.wp_ajax('product_archive_filter', filtervalues);
			this.fade_out();
		},
	});

	var TextboxControl = Sage.WooCommerce.TextboxControl = Backbone.View.extend({
		events: {
			'input input': 'input_changed',
			'change input': 'update_model',
			'keyup input': 'stop_enter_key',
		},
		placeholder: '',
		initialize: function(options) {
			options = options || {};
			this.label = options.label;
			this.placeholder = options.placeholder || this.placeholder;
			this.listenTo(this.model, 'change', this.update_from_model);
			this.render();
			if(_.isString(this.model.get())) { this.update_from_model(); } else { this.reset(); }
		},
		render: function() {
			this.$el.empty().addClass('archive-filter-control');
			if(this.label) { $('<label/>').text(this.label).appendTo(this.$el); }
			this.input = $('<input/>').attr('type', 'text').attr('placeholder', this.placeholder).addClass('form-control').appendTo(this.$el);
		},
		reset: function() {
			this.model.set('');
		},
		update_from_model: function() {
			this.input.val(this.model.get());
		},
		input_changed: function() {
			if(this.timer) { clearTimeout(this.timer); this.timer = null; }
			this.timer = setTimeout(_.bind(this.update_model, this), 1500);
		},
		update_model: function() {
			if(this.timer) { clearTimeout(this.timer); this.timer = null; }
			this.model.set(this.input.val());
		},
		stop_enter_key: function(e) {
			if (e.keyCode === 13) {
				this.update_model();
				return false;
			}
		},
	});

	var TaxonomyControl = Sage.WooCommerce.TaxonomyControl = Backbone.View.extend({
		events: {
			'change select': 'update_model',
			'click .archive-filter-clear': 'reset',
		},
		initialize: function(options) {
			options = options || {};
			this.label = options.label;
			this.clearable = options.clearable;
			if(!options.taxonomy) { throw 'Taxonomy Control must be provided a taxonomy name'; }
			var data = Sage.Util.get_preload('taxonomy_'+options.taxonomy);
			if(!data) { throw 'Unable to find preload data for '+options.taxonomy+' taxonomy!'; }
			this.tax_name = data.labels.singular_name;
			this.tax_terms = data.terms;
			this.listenTo(this.model, 'change', this.update_from_model);
			this.render();
			if(_.isString(this.model.get())) { this.update_from_model(); } else { this.reset(); }
		},
		render: function() {
			this.$el.empty().addClass('archive-filter-control archive-filter-taxonomy');
			if(this.label) { $('<label/>').text(this.label).appendTo(this.$el); }
			this.input = $('<select/>').appendTo(this.$el);
			$('<option/>').attr('value', '').text(this.tax_name).appendTo(this.input);
			for(var i = 0; i < this.tax_terms.length; i++) {
				var term = this.tax_terms[i];
				$('<option/>').attr('value', term.slug).text(term.name).appendTo(this.input);
			}
			if(this.clearable) {
				$('<div/>').addClass('archive-filter-clear').appendTo(this.$el);
			}
		},
		reset: function() {
			this.model.set('');
		},
		update_from_model: function() {
			this.input.val(this.model.get());
		},
		update_model: function() {
			this.model.set(this.input.val());
		},
	});

	var MultiTaxonomyControl = Sage.WooCommerce.MultiTaxonomyControl = Backbone.View.extend({
		initialize: function(options) {
			options = options || {};
			this.label = options.label;
			if(!options.taxonomy) { throw 'Multi Taxonomy Control must be provided a taxonomy name'; }
			var data = Sage.Util.get_preload('taxonomy_'+options.taxonomy);
			if(!data) { throw 'Unable to find preload data for '+options.taxonomy+' taxonomy!'; }
			this.taxonomy = options.taxonomy;
			this.add_text = Sage.Util.format(SageStrings.add_item, data.labels.singular_name);
			this.listenTo(this.model, 'change', this.update_from_model);
			this.render();
			if(_.isString(this.model.get())) { this.update_from_model(); } else { this.reset(); }
		},
		render: function() {
			this.$el.empty().addClass('archive-filter-control archive-filter-multitaxonomy');
			if(this.label) { $('<label/>').text(this.label).appendTo(this.$el); }
			$('<div/>').addClass('archive-filter-multitaxonomy-list').appendTo(this.$el);
		},
		render_taxonomies: function() {
			var taxonomies = this.$('.archive-filter-multitaxonomy-list').empty();

			if(this.data) { this.stopListening(this.data); }
			this.data = new Backbone.Model();

			var values = this.model.get();
			var i = 0;
			if(values.length > 0) {
				values = values.split(',');
				for(i = 0; i < values.length; i++) {
					var model = new Sage.Submodel(this.data, i);
					model.set(values[i]);
					new TaxonomyControl({
						el: $('<div/>').addClass('archive-filter-taxonomy').appendTo(taxonomies),
						taxonomy: this.taxonomy,
						model: model,
						clearable: true,
					});
				}
			}
			new TaxonomyControl({
				el: $('<div/>').addClass('archive-filter-taxonomy').appendTo(taxonomies),
				taxonomy: this.taxonomy,
				model: new Sage.Submodel(this.data, i+1),
			});

			this.listenTo(this.data, 'change', this.update_model);
		},
		reset: function() {
			this.model.set('');
		},
		update_from_model: function() {
			this.render_taxonomies();
		},
		update_model: function() {
			var values = [];
			for(var attr in this.data.attributes) {
				var value = this.data.get(attr);
				if(value) { values.push(value); }
			}
			this.model.set(_.unique(values).join(','));
			this.render_taxonomies();
		},
	});

	var RangeSliderControl = Sage.WooCommerce.RangeSliderControl = Backbone.View.extend({
		events: {
			'slide .archive-filter-slider-input': 'update_model',
		},
		defaultoptions: {min: 0, max: 100, exclude_ends: false},
		initialize: function(options) {
			options = options || {};
			this.label = options.label;
			this.labels = options.labels;
			this.options = _.extend(this.defaultoptions, options);
			this.listenTo(this.model, 'change', this.update_from_model);
			this.render();
			if(_.isObject(this.model.get())) { this.update_from_model(); } else { this.reset(); }
		},
		render: function() {
			this.$el.empty().addClass('archive-filter-control archive-filter-slider archive-filter-rangeslider');
			if(this.label) { $('<label/>').text(this.label).appendTo(this.$el); }
			this.input = $('<div/>').addClass('archive-filter-slider-input').appendTo(this.$el);
			this.input.slider({range: true, min: this.options.min, max: this.options.max});
			if(this.labels) {
				this.input.addClass('has-labels');
				for(var label in this.labels) {
					var position = parseFloat(label);
					$('<label>'+this.labels[label]+'</label>').css('left',(position*100)+'%').appendTo(this.input);
				}
			}
		},
		update_from_model: function() {
			var value = this.model.get();
			this.input.slider('values', [
				value.min ? value.min : this.options.min,
				value.max ? value.max : this.options.max,
			]);
		},
		update_model: function(event, ui) {
			var values = {min: ui.values[0], max: ui.values[1]};
			if(this.options.exclude_ends) {
				if(values.max === this.options.max) { delete values.max; }
				if(values.min === this.options.min) { delete values.min; }
			}
			this.model.set(values);
		},
		reset: function() {
			if(this.options.exclude_ends) {
				this.model.set({});
			} else {
				this.model.set({min: this.options.min, max: this.options.max});
			}
		},
	});

	var ArchiveFilterView = Sage.WooCommerce.ArchiveFilterView = Backbone.View.extend({
		events: {
			'click .reset-filters': 'reset',
			'show.bs.collapse .archive-filter-content': 'on_open',
			'hide.bs.collapse .archive-filter-content': 'on_close',
		},
		initialize: function(options) {
			this.render();
			this.$('.collapse').collapse('show');
		},
		render: function() {
			this.controls = {};

			var column1 = $('<div/>').addClass('archive-filter-column');
			this.controls.product_cat = new TaxonomyControl({
				el: $('<div/>').addClass('archive-filter-product_cat').appendTo(column1),
				taxonomy: 'product_cat',
				label: SageStrings.select_category,
				model: new Sage.Submodel(this.model, 'product_cat'),
			});
			this.controls.states = new MultiTaxonomyControl({
				el: $('<div/>').addClass('archive-filter-states').appendTo(column1),
				taxonomy: 'states',
				label: SageStrings.select_state,
				model: new Sage.Submodel(this.model, 'states'),
			});

			var column2 = $('<div/>').addClass('archive-filter-column');
			this.controls.course_duration = new RangeSliderControl({
				el: $('<div/>').addClass('archive-filter-course_duration').appendTo(column2),
				label: SageStrings.course_duration,
				labels: {
					'0': '1hr',
					'0.5': '6hr',
					'1': '12hr+',
				},
				min: 1,
				max: 12,
				exclude_ends: true,
				model: new Sage.Submodel(this.model, 'course_duration'),
			});
			this.controls.search = new TextboxControl({
				el: $('<div/>').addClass('archive-filter-search').appendTo(column2),
				placeholder: SageStrings.search,
				model: new Sage.Submodel(this.model, 'search'),
			});

			this.$('.archive-filter-controls').empty().append(column1).append(column2);
		},
		reset: function() {
			for(var control in this.controls) {
				this.controls[control].reset();
			}
		},
		on_open: function() {
			this.$('.archive-filter-header a').addClass('open');
		},
		on_close: function() {
			this.$('.archive-filter-header a').removeClass('open');
		},
	});

	var ArchiveBreadcrumbsView = Sage.WooCommerce.ArchiveBreadcrumbsView = Backbone.View.extend({
		events: {
			'click .archive-breadcrumb-remove': 'remove_breadcrumb',
		},
		initialize: function(options) {
			this.product_cat = Sage.Util.get_preload('taxonomy_product_cat');
			this.states = Sage.Util.get_preload('taxonomy_states');
			if(!this.product_cat || !this.states) { throw 'Unable to find preload data taxonomies!'; }

			this.listenTo(this.model, 'change:filter', this.render);
			this.render();
		},
		render: function() {
			this.$el.empty();
			var filters = this.model.get('filter');

			if(filters.course_duration) {
				var value = filters.course_duration;
				var text = '';
				if(value.min && value.max) { text = value.min+'-'+value.max+'hrs'; }
				else if(value.min) { text = 'Longer than '+value.min+'hrs'; }
				else if(value.max) { text = 'Shorter than '+value.max+'hrs'; }
				if(text) {
					var breadcrumb = $('<div/>').addClass('archive-breadcrumb course_duration').text(text).appendTo(this.$el);
					$('<div/>').addClass('archive-breadcrumb-remove').appendTo(breadcrumb);
				}
			}

			if(filters.states) {
				var terms = filters.states.split(',');
				for (var k = 0; k < terms.length; k++) {
					for (var i = 0; i < this.states.terms.length; i++) {
						if(this.states.terms[i].slug === terms[k]) {
							var breadcrumb = $('<div/>').addClass('archive-breadcrumb states').text(this.states.terms[i].name).appendTo(this.$el);
							breadcrumb.attr('data-value', terms[k]);
							$('<div/>').addClass('archive-breadcrumb-remove').appendTo(breadcrumb);
							break;
						}
					}
				}
			}

			if(filters.product_cat) {
				var text = '';
				for (var i = 0; i < this.product_cat.terms.length; i++) {
					if(this.product_cat.terms[i].slug === filters.product_cat) {
						text = this.product_cat.terms[i].name;
						break;
					}
				}
				var breadcrumb = $('<div/>').addClass('archive-breadcrumb product_cat').text(text).appendTo(this.$el);
				$('<div/>').addClass('archive-breadcrumb-remove').appendTo(breadcrumb);
			}

			if(filters.search) {
				var breadcrumb = $('<div/>').addClass('archive-breadcrumb search').text('Search: '+filters.search).appendTo(this.$el);
				$('<div/>').addClass('archive-breadcrumb-remove').appendTo(breadcrumb);
			}
		},
		remove_breadcrumb: function(event) {
			var breadcrumb = $(event.target).parent();

			if(breadcrumb.is('.course_duration')) {
				var filters = _.clone(this.model.get('filter'));
				filters.course_duration = {};
				this.model.set('filter', filters);
			}

			if(breadcrumb.is('.states')) {
				var filters = _.clone(this.model.get('filter'));
				var terms = filters.states.split(',');
				terms = _.reject(terms, function(x) { return x === breadcrumb.attr('data-value'); });
				filters.states = terms.join(',');
				this.model.set('filter', filters);
			}

			if(breadcrumb.is('.product_cat')) {
				var filters = _.clone(this.model.get('filter'));
				filters.product_cat = '';
				this.model.set('filter', filters);
			}

			if(breadcrumb.is('.search')) {
				var filters = _.clone(this.model.get('filter'));
				filters.search = '';
				this.model.set('filter', filters);
			}
		},
	});

	var ArchiveSortingView = Sage.WooCommerce.ArchiveSortingView = Backbone.View.extend({
		events: {
			'change .archive-sorting-input': 'update_model',
		},
		initialize: function(options) {
			this.render();
			this.listenTo(this.model, 'change:sorting', this.render);
		},
		render: function() {
			this.$('.archive-sorting-input').val(this.model.get('sorting'));
		},
		update_model: function() {
			this.model.set('sorting', this.$('.archive-sorting-input').val());
		},
	});

})(jQuery);
