(function($) {

	$.fn.loading = function(enable) {
		return this.each(function(index, element) {
			var el = $(element);
			if(enable) {
				if(el.data('loading-element')) { return; }
				if(el.css('position') !== 'absolute') { el.css('position', 'relative'); }
				var loader = $('<div/>').addClass('loading-element').appendTo(el);
				loader.css('border-radius', el.css('border-radius'));
				el.data('loading-element', loader);
			} else {
				var loader = el.data('loading-element');
				if(loader) {
					loader.remove();
					el.data('loading-element', null);
				}
			}
		});
	};

})(jQuery);
